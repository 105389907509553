import classNames from 'classnames'
import { navigate } from 'gatsby'
import React from 'react'
import Modal, { type ModalProps } from 'components/Modal'
import ModalBox from 'components/ModalBox'
import paths from 'constants/paths'
import EnterpriseOctopusUserForm from 'features/enterprise/octopus/components/EnterpriseOctopusUserForm'

type Props = {
  userData: unknown
} & ModalProps

export default function EnterpriseOctopusUserModal({ className, userData, ...props }: Props) {
  function handleSuccess() {
    navigate(paths.ONBOARDING_QUESTIONS)
  }

  return (
    <Modal
      {...props}
      hideClose
      shouldCloseOnOverlayClick={false}
      className={classNames('EnterpriseOctopusUserModal', className)}
      onRequestClose={() => {}}>
      <ModalBox title="Please verify your account information">
        <EnterpriseOctopusUserForm data={userData} onSuccess={handleSuccess} />
      </ModalBox>
    </Modal>
  )
}
