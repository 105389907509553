import classNames from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import FieldDateOfBirth from 'components/FieldDateOfBirth'
import FieldEmail from 'components/FieldEmail'
import FieldFirstName from 'components/FieldFirstName'
import FieldGender from 'components/FieldGender'
import FieldLastName from 'components/FieldLastName'
import FieldPhoneNumber from 'components/FieldPhoneNumber'
import Fields from 'components/Fields'
import * as events from 'constants/events'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as octopus from 'features/enterprise/octopus/libs/octopus'
import * as date from 'libs/date'
import * as logrocket from 'libs/logrocket'
import * as notifications from 'libs/notifications'
import * as validations from 'libs/validations'

type Props = {
  data: unknown
  onSuccess?: () => void
} & Component

export default function EnterpriseOctopusUserForm({ className, data, onSuccess }: Props) {
  const globalContext = useGlobalContext()

  React.useEffect(() => {
    globalContext.analytics?.trackEvent(events.OCTOPUS_VERIFICATION_MODAL)
  }, [globalContext.analytics])

  async function submit(values) {
    await octopus
      .getOrCreateOctopusUser({
        ...values,
        externalId: data?.id,
      })
      .then((responseData) => {
        globalContext.update(
          {
            isAuthed: true,
            isEnterprise: true,
            user: responseData.user,
          },
          async (updatedGlobalContext) => {
            updatedGlobalContext.analytics?.trackEvent(
              events.SIGN_UP,
              {
                from: 'octopus',
                signupType: 'mychart',
              },
              {
                id: responseData.user?.id,
                external_id: responseData.user?.id,
              }
            )
          }
        )
      })
      .catch((error) => {
        console.error(error)
        notifications.notifyError()
        globalContext.analytics?.trackEvent(events.SIGN_UP_FAILED, {
          error,
          from: 'octopus',
          signupType: 'mychart',
        })
      })

    notifications.notifySuccess('Information verified')

    await globalContext.updateUser()

    if (onSuccess) onSuccess()
  }

  function handleSubmit(values, formikActions) {
    submit(values, formikActions)
  }

  const fieldProps = {
    ...logrocket.INPUT_PHI_PROPS,
  }

  const hasFirstName = data?.firstName
  const hasLastName = data?.lastName

  return (
    <Formik
      enableReinitialize
      initialValues={{
        dateOfBirth: data?.dateOfBirth ? date.formatInput(data.dateOfBirth) : '',
        email: data?.email || '',
        firstName: data?.firstName || '',
        gender: data?.gender || '',
        lastName: data?.lastName || '',
        phoneNumber: data?.phoneNumber || '',
      }}
      validationSchema={Yup.object({
        email: validations.EMAIL,
        firstName: hasFirstName ? validations.NAME : undefined,
        lastName: hasLastName ? validations.NAME : undefined,
        phoneNumber: validations.PHONE_NUMBER_OPTIONAL,
      })}
      className={classNames('EnterpriseOctopusUserForm', className)}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <Fields>
            {hasFirstName && <FieldFirstName {...fieldProps} required />}
            {hasLastName && <FieldLastName {...fieldProps} required />}
          </Fields>
          <Fields>
            <FieldEmail {...fieldProps} required />
            {data?.phoneNumber && <FieldPhoneNumber {...fieldProps} />}
          </Fields>
          <Fields>
            {data?.gender && <FieldGender />}
            {data?.dateOfBirth && <FieldDateOfBirth {...fieldProps} />}
          </Fields>
          <Button disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
            Verify
          </Button>
        </Form>
      )}
    </Formik>
  )
}
