import { Form, Formik } from 'formik'
import { navigate, type PageProps } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import ContactButton from 'components/ContactButton'
import Container from 'components/Container'
import FieldEmail from 'components/FieldEmail'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Section from 'components/Section'
import Text from 'components/Text'
import Title4 from 'components/Title4'
import View from 'components/View'
import LandingFeaturedBar from 'components/landing/LandingFeaturedBar'
import LandingHero from 'components/landing/LandingHero'
import LandingHow from 'components/landing/LandingHow'
import LandingScience from 'components/landing/LandingScience'
import LandingTestimonials from 'components/landing/LandingTestimonials'
import * as events from 'constants/events'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import EnterpriseOctopusUserModal from 'features/enterprise/octopus/components/EnterpriseOctopusUserModal'
import * as epic from 'features/enterprise/octopus/libs/epic'
import * as octopus from 'features/enterprise/octopus/libs/octopus'
import * as cookie from 'libs/cookie'
import * as enterprise from 'libs/enterprise'
import { isProduction } from 'libs/environment'
import * as url from 'libs/url'
import * as userApiRequest from 'libs/user-api-request'
import * as validations from 'libs/validations'
import { ReactComponent as LogosSvg } from './images/logos.svg'
import { ReactComponent as CalendarHeartSvg } from './images/hows/calendar---heart.svg'
import { ReactComponent as ChecklistSvg } from './images/hows/checklist.svg'
import { ReactComponent as SneakerSvg } from './images/hows/sneaker.svg'

type Props = PageProps

export default function EnterpriseOctopus({ location, ...props }: Props) {
  const globalContext = useGlobalContext()
  const [epicUserData, setEpicUserData] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(true) //React.useState(!!location.search)
  const [showEscape, setShowEscape] = React.useState(false)
  const [showUserModal, setShowUserModal] = React.useState(false)

  const partner = enterprise.OCTOPUS_KEY

  React.useEffect(() => {
    globalContext.update({
      isEnterprise: true,
      partner,
    })

    cookie.createCookie(storage.HOME_KEY, partner)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    // globalContext.isAuthed isn't ready on mount
    // and adding to dep causes redirect away soon as they auth
    if (!cookie.getCookie(storage.REFRESH_TOKEN_KEY)) return

    navigate(paths.HOME_AUTHED, { replace: true })
  }, [location.pathname])

  React.useEffect(() => {
    // eslint-disable-next-line camelcase
    const { ad_id, fbclid, referral, source, src_id } = url.getQueryString()

    if (ad_id || fbclid) {
      cookie.createCookie(storage.REFERRAL_SOURCE_KEY, storage.REFERRAL_SOURCE_PAID)
    } else {
      cookie.deleteCookie(storage.REFERRAL_SOURCE_KEY)
    }

    // TODO: persistence won't work because there's no token
    globalContext.analytics?.markPageVisited(events.HOME_PAGE, {
      ad_id,
      referralId: referral,
      source,
      srcId: ad_id || fbclid ? 'facebook' : (src_id ?? partner),
    })
  }, [globalContext.analytics, partner])

  function handleSignInViaEhr(launch) {
    epic.signInFromMyChart(launch)
  }

  async function handleSignInWithCode(code) {
    const patientData = await epic.identifyEpicUserFromCode(code)
    setEpicUserData(patientData)
  }

  function getPostAuthRedirectUrl() {
    const savedRedirect = cookie.getCookie(storage.ENTERPRISE_OCTOPUS_REDIRECT_URL)
    if (savedRedirect) {
      cookie.deleteCookie(storage.ENTERPRISE_OCTOPUS_REDIRECT_URL)
      return savedRedirect
    }
    return paths.HOME_AUTHED
  }

  React.useEffect(() => {
    const { code, escape, launch, redirect } = url.getQueryString()

    if (escape) {
      setShowEscape(true)
    }

    if (redirect) {
      cookie.createCookie(storage.ENTERPRISE_OCTOPUS_REDIRECT_URL, redirect)
    }

    if (!code && !launch) {
      setIsLoading(false)
      return
    }

    if (code) {
      handleSignInWithCode(code)
    }

    if (launch) {
      handleSignInViaEhr(launch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (_.isEmpty(epicUserData)) return

    userApiRequest
      .getEnterpriseByInternalId(enterprise.getInternalId(enterprise.OCTOPUS_KEY))
      .then((enterpriseData) => {
        userApiRequest
          .getUserByEnterpriseInfo(enterpriseData.data.id, epicUserData.id)
          .then((existingUser) => {
            if (existingUser.data) {
              octopus
                .getOrCreateOctopusUser({
                  externalId: epicUserData.id,
                  ...existingUser.data,
                })
                .then((responseData) => {
                  globalContext.update(
                    {
                      isAuthed: true,
                      isEnterprise: true,
                      user: responseData.user,
                    },
                    (globalContextState) => {
                      globalContextState.analytics?.trackEvent(events.SIGN_IN, {
                        from: 'octopus',
                        loginType: 'mychart',
                      })
                      navigate(getPostAuthRedirectUrl())
                    }
                  )
                })
            } else {
              setShowUserModal(true) // replace w CD modal?
            }
          })
          .catch((error) => {
            globalContext.analytics?.trackEvent(events.SIGN_IN_FAILED, {
              error,
              from: 'octopus',
              loginType: 'mychart',
            })
          })
      })
    // calls globalContext method
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [epicUserData])

  React.useEffect(() => {
    if (!showUserModal) return
    setIsLoading(false)
  }, [showUserModal])

  function handleFakeSignIn(values) {
    const { email } = values

    const externalId = btoa(email)

    userApiRequest
      .getEnterpriseByInternalId(enterprise.getInternalId(enterprise.OCTOPUS_KEY))
      .then((enterpriseData) => {
        userApiRequest
          .getUserByEnterpriseInfo(enterpriseData.data.id, externalId)
          .then((existingUser) => {
            let fakeUserInfo = {
              firstName: 'Octopus test',
              lastName: 'Fake user',
            }
            if (existingUser.data) {
              fakeUserInfo = existingUser.data
            }
            fakeUserInfo.email = email
            fakeUserInfo.loginType = 'mychart'
            fakeUserInfo.externalId = externalId
            octopus
              .getOrCreateOctopusUser({
                externalId: epicUserData.id,
                ...fakeUserInfo,
              })
              .then((responseData) => {
                globalContext.update(
                  {
                    isAuthed: true,
                    isEnterprise: true,
                    user: responseData.user,
                  },
                  (globalContextState) => {
                    globalContextState.analytics?.trackEvent(events.SIGN_IN, {
                      from: 'octopus',
                      loginType: 'mychart',
                    })
                    navigate(getPostAuthRedirectUrl())
                  }
                )
              })
          })
          .catch((error) => {
            globalContext.analytics?.trackEvent(events.SIGN_IN_FAILED, {
              error,
              from: 'octopus',
              loginType: 'mychart',
            })
          })
      })
  }

  function handleEscapeClick() {
    cookie.deleteCookie(storage.HOME_KEY)
  }

  const hows = [
    {
      title: 'Take your personalization quiz',
      copy: 'Get a custom Bold program based on your answers',
      icon: ChecklistSvg,
    },
    {
      title: 'Start your exercise program',
      copy: 'Get weekly guided workout video classes',
      icon: SneakerSvg,
    },
    {
      title: 'Track your progress',
      copy: 'Join monthly challenges and earn Bold badges',
      icon: CalendarHeartSvg,
    },
  ]

  const name = enterprise.getName(partner)
  const title = `Age Bold + ${name}`

  const authButton = (
    <Button
      color="yellow"
      size="xlarge"
      // purposely passing no event params
      onClick={() => {
        globalContext.analytics?.trackEvent(events.ATTEMPTED_SIGN_IN)
        epic.signIn()
      }}>
      Sign in with MyChart to access Bold
    </Button>
  )

  // somehow doesn't render…
  //if (isLoading) {
  //  return <Loader />
  //}

  return (
    <>
      {isLoading && <Loader />}
      {!isProduction && (
        <View color="grey">
          <Container flush size="small">
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object({
                email: validations.REQUIRED,
              })}
              onSubmit={handleFakeSignIn}>
              {(formikProps) => (
                <Form>
                  <FieldEmail label="Fake email" name="email" />
                  <Button disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
                    Fake sign in
                  </Button>
                </Form>
              )}
            </Formik>
          </Container>
        </View>
      )}
      <Layout
        {...props}
        headerProps={{
          authButton: <ContactButton />,
        }}
        className="EnterpriseOctopus"
        data-test-id="home">
        <Head title={title} />
        <EnterpriseOctopusUserModal isOpen={showUserModal} userData={epicUserData} />
        <LandingHero
          title={<LogosSvg aria-label={title} />}
          subtext={`Bold is now included in ${name} to bring members free online balance and strength exercise programs, designed to improve physical function and support healthier aging.`}
          authProps={
            <>
              <Row flush={!showEscape}>{authButton}</Row>
              {showEscape && (
                <Text element="p" flush>
                  Are you not enrolled in the Ochsner Connected Stability Program?{' '}
                  <Link to={paths.HOME} onClick={handleEscapeClick}>
                    <Text element="u">Access your Bold account here</Text>
                  </Link>
                </Text>
              )}
            </>
          }
        />
        <LandingFeaturedBar />
        <Row size="xlarge">
          <LandingScience />
        </Row>
        <Container size="xxlarge">
          <Row size="xxxlarge">
            <LandingHow auth={false} data={hows} />
          </Row>
          <LandingTestimonials />
        </Container>
        <Section size="large">
          <Container size="medium">
            <Title4
              subtext="Ready to receive your free exercise classes?"
              actions={authButton}
              flush
              size="small">
              Get started
            </Title4>
          </Container>
        </Section>
      </Layout>
    </>
  )
}
